import React from "react";
import { some } from "lodash";

import { Provider } from "react-redux";

import store from "./src/store/index";

// Import all CSS dependencies
import "./src/scss/styles.scss";

export const wrapRootElement = ({ element }) => {
  // const store = configureStore();
  return <Provider store={store}>{element}</Provider>;
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;

  console.log("pathname", pathname);

  if (pathname.includes("/contact/")) {
    return false;
  }

  window.scrollTo(0, 0);
};

export const onRouteUpdate = () => {
  if (document?.cookie?.includes("bnae-ga=true")) {
    const currentScripts = document.querySelectorAll("script");

    if (
      !some(
        currentScripts,
        (script) =>
          script.src ===
          "https://www.googletagmanager.com/gtag/js?id=G-NXJL123NVH"
      )
    ) {
      let scriptAsync = document.createElement("script");
      scriptAsync.src =
        "https://www.googletagmanager.com/gtag/js?id=G-NXJL123NVH";
      scriptAsync.async = true;
      document.body.appendChild(scriptAsync);
    }

    if (
      !some(currentScripts, (script) =>
        script.innerHTML.includes("UA-47631299-11")
      )
    ) {
      let scriptInner = document.createElement("script");
      scriptInner.innerHTML = `
            window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
    
                        gtag('config', 'G-NXJL123NVH');
            `;
      document.body.appendChild(scriptInner);
    }
  } else {
    const docScripts = document?.querySelectorAll("script");

    const asyncScript = Array.from(docScripts)?.find((script) => {
      return (
        script.src ===
        "https://www.googletagmanager.com/gtag/js?id=G-NXJL123NVH"
      );
    });
    if (asyncScript) {
      asyncScript.parentNode.removeChild(asyncScript);
    }

    const innerScript = Array.from(docScripts)?.find((script) => {
      return script.innerHTML.includes("G-NXJL123NVH");
    });

    if (innerScript) {
      innerScript.parentNode.removeChild(innerScript);
    }
  }
};
