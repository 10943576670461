exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-existing-js": () => import("./../../../src/pages/contact/existing.js" /* webpackChunkName: "component---src-pages-contact-existing-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact/form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-new-js": () => import("./../../../src/pages/contact/new.js" /* webpackChunkName: "component---src-pages-contact-new-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-arcade-machine-js": () => import("./../../../src/templates/arcade_machine.js" /* webpackChunkName: "component---src-templates-arcade-machine-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-home-product-js": () => import("./../../../src/templates/home_product.js" /* webpackChunkName: "component---src-templates-home-product-js" */),
  "component---src-templates-management-solution-js": () => import("./../../../src/templates/management_solution.js" /* webpackChunkName: "component---src-templates-management-solution-js" */),
  "component---src-templates-manufacturer-js": () => import("./../../../src/templates/manufacturer.js" /* webpackChunkName: "component---src-templates-manufacturer-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news_post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-prize-js": () => import("./../../../src/templates/prize.js" /* webpackChunkName: "component---src-templates-prize-js" */),
  "component---src-templates-search-results-js": () => import("./../../../src/templates/search_results.js" /* webpackChunkName: "component---src-templates-search-results-js" */)
}

