import { configureStore } from "@reduxjs/toolkit";

import contactReducer from "./contact";
import productsReducer from "./products";
import searchReducer from "./search";

const store = configureStore({
    reducer: {
        contact: contactReducer,
        products: productsReducer,
        search: searchReducer,
    }
});

export default store;
