import { createSlice } from "@reduxjs/toolkit";

const initialContactState = {
    isExistingCustomer: false,
    isPurchasingMachines: false,
    isPurchasingPrizes: false,
    isTalkingManagement: false,
    isTalkingSpares: false,
    isTalkingTech: false,
};

const contactSlice = createSlice({
    name: "contact",
    initialState: initialContactState,
    reducers: {
        setIsExistingCustomer(state, action) {
            state.isExistingCustomer = action.payload;
        },
        setIsPurchasingMachines(state, action) {
            state.isPurchasingMachines = action.payload;
        },
        setIsPurchasingPrizes(state, action) {
            state.isPurchasingPrizes = action.payload;
        },
        setIsTalkingManagement(state, action) {
            state.isTalkingManagement = action.payload;
        },
        setIsTalkingSpares(state, action) {
            state.isTalkingSpares = action.payload;
        },
        setIsTalkingTech(state, action) {
            state.isTalkingTech = action.payload;
        },
    },
});

export const contactActions = contactSlice.actions;

export default contactSlice.reducer;
