import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
    arcadeMachines: [],
    isSkippingCategories: false,
    prizes: [],
    managementSolutions: [],
    manufacturerList: [],
    selectedManufacturer: null,
};

const productsSlice = createSlice({
    name: 'products',
    initialState: initialProductsState,
    reducers: {
        setArcadeMachines(state, action) {
            state.arcadeMachines = action.payload;
        },
        setIsSkippingCategories(state, action) {
            state.isSkippingCategories = action.payload;
        },
        setPrizes(state, action) {
            state.prizes = action.payload;
        },
        setManagementSolutions(state, action) {
            state.managementSolutions = action.payload;
        },
        setManufacturerList(state, action) {
            state.manufacturerList = action.payload;
        },
        setSelectedManufacturer(state, action) {
            state.selectedManufacturer = action.payload;
        },
    }
});

export const productsActions = productsSlice.actions;

export default productsSlice.reducer;
