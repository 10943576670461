import { createSlice } from "@reduxjs/toolkit";

const initialSearchState = {
    searchQuery: null,
};

const searchSlice = createSlice({
    name: 'search',
    initialState: initialSearchState,
    reducers: {
        setSearchQuery(state, action) {
            state.searchQuery = action.payload;
        },
    }
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
